// store/modules/vacancy.js

export default {
  namespaced: true,
  state() {
    return {
      vacancies: [],
    };
  },
  mutations: {
    ADD_VACANCY(state, vacancy) {
      const existingIndex = state.vacancies.findIndex(item =>
        item.date === vacancy.date &&
        item.time === vacancy.time &&
        item.venueId === vacancy.venueId
      );

      if (existingIndex === -1) {
        state.vacancies.push(vacancy);
      }
    },
    REMOVE_VACANCY(state, vacancyToRemove) {
      state.vacancies = state.vacancies.filter(item =>
        !(item.date === vacancyToRemove.date &&
          item.time === vacancyToRemove.time &&
          item.venueId === vacancyToRemove.venueId)
      );
    },
  },
};