import { login, logout, refreshToken } from "@/services/auth";
import { encodeByAES256 } from "@/utils/crypto";

import { showToast } from "@/utils/toast";

export default {
  namespaced: true,
  state() {
    return {
      waitChatList: [],  // Add this
      counselChatList: []  // Add this
    };
  },
  getters: {
    getWaitChatList: (state) => state.waitChatList,  // Add this
    getCounselChatList: (state) => state.counselChatList  // Add this
  },
  mutations: {
    SET_WAIT_CHAT_LIST(state, payload) {  // Add this
      state.waitChatList = payload;
    },
    SET_COUNSEL_CHAT_LIST(state, payload) {  // Add this
      state.counselChatList = payload;
    }
  },
  actions: {
    async setChatList({ commit }, { type, data }) {  // Modify this
      if (type === 'wait') {
        commit('SET_WAIT_CHAT_LIST', data);
      } else if (type === 'counsel') {
        commit('SET_COUNSEL_CHAT_LIST', data);
      }
    },
  },
};
