import { getManagerId } from "@/utils/id";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "AdminView",
    component: () => import("@/layout/MainLayout.vue"),
    children: [
      {
        path: "",
        name: "HomeView",
        component: () => import("@/views/Home/HomeView.vue"),
      },
      {
        path: "customer",
        component: () => import("@/views/Customer/CustomerLayout.vue"),
        children: [
          {
            path: "",
            name: "CustomerView",
            component: () => import("@/views/Home/HomeView.vue"),
            beforeEnter: (to, from, next) => {
              next({ name: "CustomerCareView" });
            },
          },
          {
            path: "all",
            name: "CustomerCareView",
            meta: {
              parent: "CustomerView",
            },
            component: () => import("@/views/Customer/CustomerCareView.vue"),
          },
          {
            path: "all/:id",
            name: "CustomerCareDetailView",
            meta: {
              parent: "CustomerView",
            },
            component: () => import("@/views/Customer/CustomerDetailView.vue"),
          },
          {
            path: "all/:id/:status",
            name: "CustomerCareDetailWithStatusView",
            meta: {
              parent: "CustomerView",
            },
            component: () => import("@/views/Customer/CustomerDetailView.vue"),
          },
          {
            path: "chat-consultation",
            name: "CustomerChatConsultationView",
            meta: {
              parent: "CustomerView",
            },
            component: () =>
              import("@/views/Customer/CustomerChatConsultationView.vue"),
          },
          {
            path: "chat-consultation/:id",
            name: "CustomerChatConsultationDetailView",
            meta: {
              parent: "CustomerView",
            },
            component: () =>
              import("@/views/Customer/CustomerDetailView.vue"),
          },
          {
            path: "telephone-consultation",
            name: "CustomerTelephoneConsultationView",
            meta: {
              parent: "CustomerView",
            },
            component: () =>
              import("@/views/Customer/CustomerTelephoneConsultationView.vue"),
          },
          {
            path: "telephone-consultation/:id",
            name: "CustomerTelephoneConsultationDetailView",
            meta: {
              parent: "CustomerView",
            },
            component: () =>
              import("@/views/Customer/CustomerDetailView.vue"),
          },
          {
            path: "visit-consultation",
            name: "CustomerVisitConsultationView",
            meta: {
              parent: "CustomerView",
            },
            component: () =>
              import("@/views/Customer/CustomerVisitConsultationView.vue"),
          },
          {
            path: "visit-consultation/:id",
            name: "CustomerVisitConsultationDetailView",
            meta: {
              parent: "CustomerView",
            },
            component: () =>
              import("@/views/Customer/CustomerDetailView.vue"),
          },
          {
            path: "tasting",
            name: "CustomerTastingView",
            meta: {
              parent: "CustomerView",
            },
            component: () => import("@/views/Customer/CustomerTastingView.vue"),
          },
          {
            path: "tasting/:id",
            name: "CustomerTastingDetailView",
            meta: {
              parent: "CustomerView",
            },
            component: () => import("@/views/Customer/CustomerDetailView.vue"),
          },
          {
            path: "tasting/:id/:status",
            name: "CustomerTastingDetailWithStatusView",
            meta: {
              parent: "CustomerView",
            },
            component: () => import("@/views/Customer/CustomerDetailView.vue"),
          },
          {
            path: "contract",
            name: "CustomerContractView",
            meta: {
              parent: "CustomerView",
            },
            component: () =>
              import("@/views/Customer/CustomerContractView.vue"),
          },
          {
            path: "contract/:id",
            name: "CustomerContractDetailView",
            meta: {
              parent: "CustomerView",
            },
            component: () =>
              import("@/views/Customer/CustomerDetailView.vue"),
          },
          {
            path: "contract/:id/:status",
            name: "CustomerContractDetailWithStatusView",
            meta: {
              parent: "CustomerView",
            },
            component: () => import("@/views/Customer/CustomerDetailView.vue"),
          },
          {
            path: "ceremony-complete",
            name: "CustomerCeremonyCompleteView",
            meta: {
              parent: "CustomerView",
            },
            component: () =>
              import("@/views/Customer/CustomerCeremonyCompleteView.vue"),
          },
          {
            path: "ceremony-complete/:id",
            name: "CustomerCeremonyCompleteDetailView",
            meta: {
              parent: "CustomerView",
            },
            component: () =>
              import("@/views/Customer/CustomerDetailView.vue"),
          },
          {
            path: "event",
            name: "CustomerEventView",
            meta: {
              parent: "CustomerView",
            },
            component: () => import("@/views/Customer/CustomerEventView.vue"),
          },
          {
            path: "event/:id",
            name: "CustomerEventDetailView",
            meta: {
              parent: "CustomerView",
            },
            component: () => import("@/views/Customer/CustomerDetailView.vue"),
          },
          {
            path: "delete",
            name: "CustomerDeleteView",
            meta: {
              parent: "CustomerView",
            },
            component: () => import("@/views/Customer/CustomerDeleteView.vue"),
          },
        ],
      },
      {
        path: "customer/register",
        name: "CustomerRegistrationView",
        meta: {
          parent: "CustomerView",
        },
        component: () =>
          import("@/views/Customer/CustomerRegistrationView.vue"),
      },
      {
        path: "wedding-talk",
        component: () => import("@/views/WeddingTalk/TalkLayout.vue"),
        children: [
          {
            path: "",
            name: "WeddingTalkView",
            component: () => import("@/views/Home/HomeView.vue"),
            beforeEnter: (to, from, next) => {
              next({ name: "ChatListView" });
            },
          },
          {
            path: "chat-list",
            name: "ChatListView",
            meta: {
              parent: "WeddingTalkView",
            },
            component: () => import("@/views/WeddingTalk/ChatListView.vue"),
          },
          {
            path: "chat-settings",
            name: "ChatSettingView",
            meta: {
              parent: "WeddingTalkView",
            },
            component: () => import("@/views/WeddingTalk/ChatSettingView.vue"),
          },
          {
            path: "faq",
            name: "FAQView",
            meta: {
              parent: "WeddingTalkView",
            },
            component: () => import("@/views/WeddingTalk/FAQView.vue"),
          },
        ],
      },
      {
        path: "/wedding-talk/faq/add",
        name: "AddFAQView",
        meta: {
          parent: "WeddingTalkView",
        },
        component: () => import("@/views/WeddingTalk/AddFAQView.vue"),
      },
      {
        path: "/wedding-talk/faq/:id",
        name: "EditFAQView",
        meta: {
          parent: "WeddingTalkView",
        },
        component: () => import("@/views/WeddingTalk/AddFAQView.vue"),
      },
      {
        path: "daily-task",
        name: "DailyTaskView",
        component: () => import("@/views/ScheduleDailyTask/DailyTaskView.vue"),
      },
      {
        path: "full-schedule",
        name: "FullScheduledView",
        component: () => import("@/views/FullSchedule/FullScheduleView.vue"),
      },
      {
        path: "hall-setting",
        component: () => import("@/views/HallSetting/HallLayout.vue"),
        children: [
          {
            path: "",
            name: "HallSettingsView",
            component: () => import("@/views/HallSetting/HallSettingsView.vue"),
          },
          {
            path: "register",
            name: "HallRegistrationView",
            meta: {
              parent: "HallSettingsView",
            },
            component: () =>
              import("@/views/HallSetting/HallRegistrationView.vue"),
          },
          {
            path: "view/:id",
            name: "HallDetailView",
            meta: {
              parent: "HallSettingsView",
            },
            component: () => import("@/views/HallSetting/HallDetailView.vue"),
          },
          {
            path: "edit/:id",
            name: "HallEditView",
            meta: {
              parent: "HallSettingsView",
            },
            component: () =>
              import("@/views/HallSetting/HallEditView.vue"),
          },
        ],
      },
      {
        path: "vacancy-management",
        name: "VacancyManagementView",
        component: () => import("@/views/Vacancy/VacancyManagementView.vue"),
      },
      {
        path: "note",
        name: "NoteView",
        component: () => import("@/views/Note/NoteView.vue"),
      },
      // 고객 정산
      {
        path: "customer-settlement",
        name: "CustomerSettlementView",
        component: () =>
          import("@/views/CustomerSettlement/CustomerSettlementView.vue"),
      },
      // 리베이트 정산
      {
        path: "rebate-settlement",
        name: "RebateSettlementView",
        component: () =>
          import("@/views/RebateSettlement/RebateSettlementView.vue"),
      },
      // 거래처 정산
      {
        path: "account-settlement",
        name: "AccountSettlementView",
        component: () =>
          import("@/views/AccountSettlement/AccountSettlementView.vue"),
      },
      // 고객 정산 > 상세 페이지
      {
        path: 'customer-settlement/:id',
        name: "CustomerSettlementDetailView",
        meta: {
          parent: 'CustomerSettlementView'
        },
        component: () => import("@/views/CeremonyDetail/CeremonyDetailView.vue"),
      },
      // 리베이트 정산 > 상세 페이지
      {
        path: 'rebate-settlement/:id',
        name: "RebateSettlementDetailView",
        meta: {
          parent: 'RebateSettlementView'
        },
        component: () => import("@/views/CeremonyDetail/CeremonyDetailView.vue"),
      },
      // 거래처 정산 > 상세 페이지
      {
        path: 'account-settlement/:id',
        name: "AccountSettlementDetailView",
        meta: {
          parent: 'AccountSettlementView'
        },
        component: () => import("@/views/CeremonyDetail/CeremonyDetailView.vue"),
      },
      {
        path: "general-statistics",
        component: () =>
          import("@/views/GeneralStatistic/GeneralStatisticLayout.vue"),
        children: [
          {
            path: ":cycle?",
            name: "GeneralStatisticsView",
            component: () =>
              import("@/views/GeneralStatistic/GeneralStatisticView.vue"),
          },
        ],
      },
      {
        path: "marketing-statistics",
        component: () =>
          import("@/views/MarketingStatistic/MarketingStatisticLayout.vue"),
        children: [
          {
            path: ":cycle?",
            name: "MarketingStatisticsView",
            component: () =>
              import("@/views/MarketingStatistic/MarketingStatisticView.vue"),
          },
        ],
      },
      {
        path: "customer-management",
        component: () =>
          import(
            "@/views/Setting/CustomerManagement/CustomerManagementLayout.vue"
          ),
        children: [
          {
            path: "",
            name: "CustomerManagementView",
            component: () => import("@/views/Home/HomeView.vue"),
            beforeEnter: (to, from, next) => {
              next({ name: "GeneralBusinessView" });
            },
          },
          {
            path: "general-business",
            name: "GeneralBusinessView",
            meta: {
              parent: "CustomerManagementView",
            },
            component: () =>
              import(
                "@/views/Setting/CustomerManagement/GeneralBusiness/GeneralBusinessView.vue"
              ),
          },
          {
            path: "company-planner",
            name: "PlannerCompanyView",
            meta: {
              parent: "CustomerManagementView",
            },
            component: () =>
              import(
                "@/views/Setting/CustomerManagement/PlannerCompanyView.vue"
              ),
          },
        ],
      },
      {
        path: "customer-management/general-business/:id",
        name: "GeneralBusinessDetailView",
        meta: {
          parent: "CustomerManagementView",
        },
        component: () =>
          import(
            "@/views/Setting/CustomerManagement/GeneralBusiness/GeneralBusinessDetailView.vue"
          ),
      },
      {
        path: "basic-setting",
        children: [
          {
            path: "",
            name: "BasicSettingView",
            component: () =>
              import(
                "@/views/Setting/BasicSetting/BusinessLocationInfoView.vue"
              ),
          },
        ],
      },
      {
        path: "administrator-setting",
        children: [
          {
            path: "",
            name: "AdministratorSettingView",
            component: () =>
              import("@/views/Setting/AdminSetting/AdminSettingView.vue"),
          },
          {
            path: "add",
            name: "AdministratorSettingAdd",
            meta: {
              parent: "AdministratorSettingView",
            },
            component: () =>
              import("@/views/Setting/AdminSetting/AdminSettingDetailView.vue"),
          },
          {
            path: ":id",
            name: "AdministratorSettingEdit",
            meta: {
              parent: "AdministratorSettingView",
            },
            component: () =>
              import("@/views/Setting/AdminSetting/AdminSettingEditView.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "LoginPage",
    component: () => import("@/views/LoginView.vue"),
  },
  {
    path: "/find/pwd",
    name: "FindPwdPage",
    component: () => import("@/views/FindPwdPage.vue"),
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

/**
 * 로그인한 상태가 아니라면 어떤 경로로 들어가던지 간에 로그인 페이지로 리디렉션
 */
router.beforeEach(async (to, from) => {
  const isAuthenticated = localStorage.getItem("access_token");
  const isHaveManagerId = getManagerId();
  const isCanUsePage = ['FindPwdPage'];
  if( isCanUsePage.includes(to.name) ){
    return true;
  } else if(
    !isAuthenticated &&
    to.name !== 'LoginPage' ||
    !Number( isHaveManagerId ) &&
    to.name !== 'LoginPage' 
  ) {
    localStorage.removeItem('access_token');
    localStorage.removeItem('managerId');
    return { name : 'LoginPage' }
  }
})

router.addRoute({
  path: '/:pathMatch(.*)*',
  component: () => import("@/views/Home/HomeView.vue")
})

export default router;
